/**
 * yProTableProps
 * y-pro-table属性
 */
const props = {
  // el-table原生属性
  tableProps: Object,
  // datapager 是否分页控件 默认展示分页控件
  datapager: {
    type: Boolean,
    default: true
  },
  // el-pagination原生属性
  pagination: [Object, Boolean],
  // 表格表头
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  // 请求函数, 必须是一个返回promise的函数
  fetch: Function,
  // 请求参数拦截函数
  processRequest: Function,
  // 接受数据拦截函数
  processResponse: Function,
  // 初始化加载
  initLoad: {
    type: Boolean,
    default: true
  }
}

export const propsKeys = Object.keys(props)

export default props
