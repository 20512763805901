const apis = [
  {
    // 轮播图分页列表
    fn: 'mpArticleGetPageList',
    method: 'POST',
    url: '/mpArticle/getPageList',
    hideLoading: true
  },
  {
    // 添加轮播图
    fn: 'mpArticleAdd',
    method: 'POST',
    url: '/mpArticle/add',
    hideLoading: true
  },
  {
    // 修改轮播图
    fn: 'mpArticleUpdate',
    method: 'POST',
    url: '/mpArticle/update',
    hideLoading: true
  },
  {
    // 删除轮播图
    fn: 'mpArticleDelete',
    method: 'POST',
    url: '/mpArticle/delete',
    hideLoading: true,
    direct: true
  },
  {
    // 轮播图详情
    fn: 'mpArticleInfo',
    method: 'GET',
    url: '/mpArticle/info',
    hideLoading: true,
    direct: true
  }
]

export default apis
