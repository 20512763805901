const isProduction = process.env.NODE_ENV === 'production'

let baseUrl = window.location.origin

if (isProduction) {
  // baseUrl = 'https://api.yhmwdesign.com/api'
  // baseUrl = 'https://gwadmin.feisiplus.com/api'
  baseUrl = 'http://gwadmin.feisiplus.com/api'
  // baseUrl = '/api'
} else {
  // baseUrl = 'https://api.yhmwdesign.com/api'
  // baseUrl = 'https://gwadmin.feisiplus.com/api'
  baseUrl = 'http://gwadmin.feisiplus.com/api'
  // baseUrl = '/api'
}

export { baseUrl }
