const apis = [
  {
    fn: 'bannerList',
    method: 'post',
    url: '/gwBanner/getPageList',
    hideLoading: true
  },
  {
    fn: 'bannerAdd',
    method: 'post',
    url: '/gwBanner/add'
  },
  {
    fn: 'bannerUpdate',
    method: 'post',
    url: '/gwBanner/update'
  },
  {
    fn: 'bannerInfo',
    method: 'get',
    url: '/gwBanner/info',
    direct: true
  },
  {
    fn: 'bannerDelete',
    method: 'post',
    url: '/gwBanner/delete',
    direct: true
  }
]

export default apis
