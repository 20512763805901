const apis = [
  {
    fn: 'caseList',
    method: 'post',
    url: '/gwCase/getPageList',
    hideLoading: true
  },
  {
    fn: 'caseAdd',
    method: 'post',
    url: '/gwCase/add'
  },
  {
    fn: 'caseUpdate',
    method: 'post',
    url: '/gwCase/update'
  },
  {
    fn: 'caseInfo',
    method: 'get',
    url: '/gwCase/info',
    direct: true
  },
  {
    fn: 'caseDelete',
    method: 'post',
    url: '/gwCase/delete',
    direct: true
  }
]

export default apis
