const apis = [
  {
    // 轮播图分页列表
    fn: 'mpBannerGetPageList',
    method: 'POST',
    url: '/mpBanner/getPageList',
    hideLoading: true
  },
  {
    // 添加轮播图
    fn: 'mpBannerAdd',
    method: 'POST',
    url: '/mpBanner/add',
    hideLoading: true
  },
  {
    // 修改轮播图
    fn: 'mpBannerUpdate',
    method: 'POST',
    url: '/mpBanner/update',
    hideLoading: true
  },
  {
    // 删除轮播图
    fn: 'mpBannerDelete',
    method: 'POST',
    url: '/mpBanner/delete',
    hideLoading: true,
    direct: true
  },
  {
    // 轮播图详情
    fn: 'mpBannerInfo',
    method: 'GET',
    url: '/mpBanner/info',
    hideLoading: true,
    direct: true
  }
]

export default apis
