const list = [
  {
    label: '无跳转',
    value: '0'
  },
  {
    label: '跳转网页',
    value: '1'
  },
  {
    label: '跳转详情',
    value: '2'
  }
]

export default list
