const apis = [
  {
    fn: 'newsList',
    method: 'post',
    url: '/gwNews/getPageList',
    hideLoading: true
  },
  {
    fn: 'newsAdd',
    method: 'post',
    url: '/gwNews/add'
  },
  {
    fn: 'newsUpdate',
    method: 'post',
    url: '/gwNews/update'
  },
  {
    fn: 'newsInfo',
    method: 'get',
    url: '/gwNews/info',
    direct: true
  },
  {
    fn: 'newsDelete',
    method: 'post',
    url: '/gwNews/delete',
    direct: true
  }
]

export default apis
