import RouterHolder from '@/components/router-holder'

import formatRoutesByEnhanceMeta from './helpers/formatRoutesByEnhanceMeta'
/**
 * @meta
 * title: 菜单名称
 * icon: 菜单显示图标
 * hideSubmenu: children 配置不显示在子菜单中
 * hideMenu: 当前配置不显示在菜单中
 */
const main = [
  {
    path: 'banner',
    name: 'banner',
    meta: { title: '官网Banner', icon: '&#xe623;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/banner',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner')
      },
      {
        path: 'add',
        meta: { title: '添加Banner' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改Banner' },
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner/add')
      }
    ]
  },
  {
    path: 'news',
    name: 'news',
    meta: { title: '官网新闻', icon: '&#xe65f;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/news/list',
    children: [
      {
        path: 'list',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "news" */ '@/views/news')
      },
      {
        path: 'add',
        meta: { title: '添加新闻' },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改新闻' },
        component: () =>
          import(/* webpackChunkName: "news" */ '@/views/news/add')
      }
    ]
  },
  {
    path: 'case',
    name: 'case',
    meta: { title: '官网案例', icon: '&#xe615;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/case/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "case" */ '@/views/case')
      },
      {
        path: 'add',
        meta: { title: '添加案例' },
        component: () =>
          import(/* webpackChunkName: "case" */ '@/views/case/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改案例' },
        component: () =>
          import(/* webpackChunkName: "case" */ '@/views/case/add')
      }
    ]
  },
  {
    path: 'type',
    name: 'type',
    meta: { title: '案例类别', icon: '&#xe605;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/type/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () => import(/* webpackChunkName: "type" */ '@/views/type')
      },
      {
        path: 'add',
        meta: { title: '添加类别' },
        component: () =>
          import(/* webpackChunkName: "type" */ '@/views/type/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改类别' },
        component: () =>
          import(/* webpackChunkName: "type" */ '@/views/type/add')
      }
    ]
  },
  {
    path: 'cooperation',
    name: 'cooperation',
    meta: { title: '合作企业', icon: '&#xe65a;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/cooperation/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "cooperation" */ '@/views/cooperation')
      },
      {
        path: 'add',
        meta: { title: '添加合作企业' },
        component: () =>
          import(
            /* webpackChunkName: "cooperation" */ '@/views/cooperation/add'
          )
      },
      {
        path: 'update/:id',
        meta: { title: '修改合作企业' },
        component: () =>
          import(
            /* webpackChunkName: "cooperation" */ '@/views/cooperation/add'
          )
      }
    ]
  },
  {
    path: 'industrial',
    name: 'industrial',
    meta: { title: '工业设计', icon: '&#xe610;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/industrial/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "industrial" */ '@/views/industrial')
      },
      {
        path: 'add',
        meta: { title: '添加工业设计' },
        component: () =>
          import(/* webpackChunkName: "industrial" */ '@/views/industrial/add')
      },
      {
        path: 'update/:id',
        meta: { title: '修改工业设计' },
        component: () =>
          import(/* webpackChunkName: "industrial" */ '@/views/industrial/add')
      }
    ]
  },
  {
    path: 'mini-banners',
    name: 'mini-banners',
    meta: { title: '小程序轮播图', icon: '&#xe610;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/mini-banners/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "mini-banners" */ '@/views/mini-banners')
      },
      {
        path: 'add',
        meta: { title: '添加小程序轮播图' },
        component: () =>
          import(
            /* webpackChunkName: "mini-banners" */ '@/views/mini-banners/add'
          )
      },
      {
        path: 'update/:id',
        meta: { title: '修改小程序轮播图' },
        component: () =>
          import(
            /* webpackChunkName: "mini-banners" */ '@/views/mini-banners/add'
          )
      }
    ]
  },
  {
    path: 'mini-article',
    name: 'mini-article',
    meta: { title: '小程序文章', icon: '&#xe610;', hideSubmenu: true },
    component: RouterHolder,
    redirect: '/mini-banners/list',
    children: [
      {
        path: '',
        meta: { title: '列表' },
        component: () =>
          import(/* webpackChunkName: "mini-article" */ '@/views/mini-article')
      },
      {
        path: 'add',
        meta: { title: '添加小程序文章' },
        component: () =>
          import(
            /* webpackChunkName: "mini-article" */ '@/views/mini-article/add'
          )
      },
      {
        path: 'update/:id',
        meta: { title: '修改小程序文章' },
        component: () =>
          import(
            /* webpackChunkName: "mini-article" */ '@/views/mini-article/add'
          )
      }
    ]
  }
]

export default formatRoutesByEnhanceMeta(main)
